import { ColorsService } from './colors.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FamiliesService
{
	public colors;

	data: Array<{id: string, name: string, label: string, title: string, images: Array<string>, colors: Array<{}>}>;

  	constructor(
  		private colorsService: ColorsService
  	){ 
  		
  		this.data = [
  			{
  				id : '0001',
  				name : 'Black & White',
  				label : 'black-and-white',
  				title: 'Black & White',
  				images: ['/assets/images/families/black-and-white/black-and-white.jpg'],
  				colors: this.colorsService.getColorsByFamily( 'black-and-white' )
  			},
  			{
  				id : '0002',
  				name : 'Marbre',
  				label : 'marbre',
  				title: 'Marbre',
  				images: ['/assets/images/families/marbre/marbre.jpg'],
  				colors: this.colorsService.getColorsByFamily( 'marbre' )
  			},
  			{
  				id : '0003',
  				name : 'Loft',
  				label : 'loft',
  				title: 'Loft',
  				images: ['/assets/images/families/loft/loft.jpg'],
  				colors: this.colorsService.getColorsByFamily( 'loft' )
  			},
  			{
  				id : '0004',
  				name : 'Madera',
  				label : 'madera',
  				title: 'Madera',
  				images: ['/assets/images/families/madera/madera.jpg'],
  				colors: this.colorsService.getColorsByFamily( 'madera' )
  			},
  			{
  				id : '0005',
  				name : 'Unis',
  				label : 'unis',
  				title: 'Unis',
  				images: ['/assets/images/families/unis/unis.jpg'],
  				colors: this.colorsService.getColorsByFamily( 'unis' )
  			},
  			{
  				id : '0006',
  				name : 'Mineralis',
  				label : 'mineralis',
  				title: 'Mineralis',
  				images: ['/assets/images/families/mineralis/mineralis.jpg'],
  				colors: this.colorsService.getColorsByFamily( 'mineralis' )
  			},
  			{
  				id : '0007',
  				name : 'Artisanal',
  				label : 'artisanal',
  				title: 'Artisanal',
  				images: ['/assets/images/families/artisanal/artisanal.jpg'],
  				colors: this.colorsService.getColorsByFamily( 'artisanal' )
  			},
  			{
  				id : '0008',
  				name : 'Vegetalis',
  				label : 'vegetalis',
  				title: 'Vegetalis',
  				images: ['/assets/images/families/vegetalis/vegetalis.jpg'],
  				colors: this.colorsService.getColorsByFamily( 'vegetalis' )
  			},
  			{
  				id : '0009',
  				name : 'Art Déco',
  				label : 'artdec',
  				title: 'Art Déco',
  				images: ['/assets/images/families/artdec/artdec.jpg'],
  				colors: this.colorsService.getColorsByFamily( 'artdec' )
  			},
  			{
  				id : '0010',
  				name : 'Panoramique',
  				label : 'panoramique',
  				title: 'Panoramique',
  				images: ['/assets/images/families/panoramique/panoramique.jpg'],
  				colors: this.colorsService.getColorsByFamily( 'panoramique' )
  			},
  			{
  				id : '0011',
  				name : 'À la Bastide',
  				label : 'a-la-bastide',
  				title: 'MK À la Bastide',
  				images: ['/assets/images/families/a-la-bastide/a-la-bastide.jpg'],
  				colors: this.colorsService.getColorsByFamily( 'a-la-bastide' )
  			},
  			{
  				id : '0012',
  				name : 'Vallon des Auffes',
  				label : 'vallon-des-auffes',
  				title: 'MK Vallon des Auffes',
  				images: ['/assets/images/families/vallon-des-auffes/vallon-des-auffes.jpg'],
  				colors: this.colorsService.getColorsByFamily( 'vallon-des-auffes' )
  			},
  			{
  				id : '0013',
  				name : 'Collector',
  				label : 'collector',
  				title: 'Collector',
  				images: ['/assets/images/families/collector/collector.jpg'],
  				colors: this.colorsService.getColorsByFamily( 'collector' )
  			}
			] 	
  	}

  	getFamilyByLabel(label)
  	{
			for ( let family of this.data ) {
  			if ( family.label == label ) {
  				return family;
  			}
  		}
  	}

  	getNextFamily(id) 
  	{
  		const families = this.data;

  		for (let i in families ) {
  			if (families[i].id == id ) {
  				let next = parseInt(i)+1;
				return next < families.length 
					? families[next] 
					: families[0];
  			}
  		}
	}

	getPreviousFamily(id)
	{
		const families = this.data;

		for (let i in families ) {
			if (families[i].id == id ) {
				let prev = parseInt(i)-1;
				return prev == -1
					? families[families.length-1]
					: families[prev];
			}
		}
	}
}
