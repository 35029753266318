import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { MenuController } from '@ionic/angular';
import { VideoPlayer } from './components/video-player/video-player.component';
import {FileSharer} from '@byteowls/capacitor-filesharer';
import { Plugins, registerWebPlugin, StatusBarBackgroundColorOptions } from '@capacitor/core';

const { SplashScreen, StatusBar } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent
{
  constructor(
    private platform: Platform,
    //private splashScreen: SplashScreen,
    //private statusBar: StatusBar,
    private menu: MenuController,
    //private router: Router
  ){
    this.initializeApp();
  }

  initializeApp()
  {
    console.log('initializeApp');
    this.platform.ready().then(() => {
      //this.statusBar.styleDefault();
      //this.statusBar.backgroundColorByHexString('#ffffff');
      //this.splashScreen.hide();
      SplashScreen.hide();
      StatusBar.setBackgroundColor({ color : '#ffffff'});
      registerWebPlugin(FileSharer);
      //this.router.navigateByUrl('home');
    });
  }

  closeMenu()
  {
    this.menu.close()
  }

  playVideo()
  {
    VideoPlayer.player.toggleVideo();
  }
}
