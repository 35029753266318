import { Injectable } from '@angular/core';

@Injectable({
  	providedIn: 'root'
})
export class ColorsService
{
	public current;

	data: Array<{
		id: string, 
		name: string, 
		images: { 
			full : string, 
			thumb : string
		}, 
		families: Array<string>, 
		description: string, 
		txtColorLight: boolean,
		supports: {} 
	}>;

	currentColor: {
		id: string, 
		family: string, 
		iteration: number
	};

  	constructor()
  	{ 
  		this.currentColor = {
  			id : "0001",
  			family : "black-and-white",
  			iteration : 0
  		}

  		this.data = [
  			{
				id : '0001',
				name : "Blanc brillant",
				images : {
					full : '/assets/images/colors/blanc-brillant/Blanc-Brillant.png',
					thumb : '/assets/images/colors/blanc-brillant/Blanc-Brillant-thumb.png'
				},
				families : ['black-and-white'],
				description : "Ce blanc laqué apportera un effet de lumière garanti à votre douche.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				}
			},
			{
				id : '0002',
				name : "Blanc mat",
				images : {
					full : '/assets/images/colors/blanc-mat/Blanc-Mat.jpg',
					thumb : '/assets/images/colors/blanc-mat/Blanc-Mat-thumb.jpg'
				},
				families : ['black-and-white'],
				description : "La beauté du blanc accentuée par un style contemporain.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				} 
			},
			{
				id : '0003',
				name : "Blanc satiné",
				images : {
					full : '/assets/images/colors/blanc-satine/Blanc-Satine.png',
					thumb : '/assets/images/colors/blanc-satine/Blanc-Satine-thumb.png'
				},
				families : ['black-and-white'],
				description : "Optez pour la simplicité du blanc lumineux.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				} 
			},
			{
				id : '0004',
				name : "Onde blanche",
				images : {
					full : '/assets/images/colors/onde-blanche/Onde-Blanche.jpg',
					thumb : '/assets/images/colors/onde-blanche/Onde-Blanche-thumb.jpg'
				},
				families : ['black-and-white'],
				description : "Effet de texture sur fond blanc qui apporte lumière, pureté et douceur.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000', '1200x2000', '900x2500', '1200x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				} 
			},
			{
				id : '0005',
				name : "Gris perle",
				images : {
					full : '/assets/images/colors/gris-perle/Gris-Perle.jpg',
					thumb : '/assets/images/colors/gris-perle/Gris-Perle-thumb.jpg'
				},
				families : ['black-and-white'],
				description : "La douceur du gris irisé pour une ambiance en toute sobriété.",
				txtColorLight: true,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				} 
			},
			{
				id : '0006',
				name : "Gris anthracite",
				images : {
					full : '/assets/images/colors/gris-anthracite/Gris-Anthracite.jpg',
					thumb : '/assets/images/colors/gris-anthracite/Gris-Anthracite-thumb.jpg'
				},
				families : ['black-and-white'],
				description : "Nuance de gris, d’argent et de noir pour un décor tout en brillance.",
				txtColorLight: true,
				supports: {
					wall: ['1200x1500'],
					// bati: [],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400']
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					// pipe: []
				} 
			},
			{
				id : '0007',
				name : "Noir mat",
				images : {
					full : '/assets/images/colors/noir-mat/Noir-Mat.jpg',
					thumb : '/assets/images/colors/noir-mat/Noir-Mat-thumb.jpg'
				},
				families : ['black-and-white'],
				description : "Une finition mate pour une salle de bain élégante.",
				txtColorLight: true,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				}
			},
			{
				id : '0008',
				name : "Marbre gold",
				images : {
					full : '/assets/images/colors/marbre-gold/Marbre-Gold.jpg',
					thumb : '/assets/images/colors/marbre-gold/Marbre-Gold-thumb.jpg'
				},
				families : ['marbre'],
				description : "Un effet à la fois luxueux et raffiné, apportant une touche de luminosité et de chaleur à votre salle de bains.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				} 
			},
			{
				id : '0009',
				name : "Ivory",
				images : {
					full : '/assets/images/colors/ivory/Ivory.jpg',
					thumb : '/assets/images/colors/ivory/Ivory-thumb.jpg'
				},
				families : ['marbre'],
				description : "S'intègre parfaitement dans un style classique, contemporain ou même minimaliste grâce à sa polyvalence et son charme intemporel.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				} 
			},
			{
				id : '0010',
				name : "Carrare",
				images : {
					full : '/assets/images/colors/carrare/Carrare.jpg',
					thumb : '/assets/images/colors/carrare/Carrare-thumb.jpg'
				},
				families : ['marbre'],
				description : "Un marbre incontournable pour une salle de bain ultra chic.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				} 
			},
			{
				id : '0011',
				name : "Calcata mat",
				images : {
					full : '/assets/images/colors/calcata-mat/Calcata-Mat.jpg',
					thumb : '/assets/images/colors/calcata-mat/Calcata-Mat-thumb.jpg'
				},
				families : ['marbre'],
				description : "Alternative au carrare simple, pour une ambiance plus contemporaine.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				} 
			},
			{
				id : '0012',
				name : "Calcata brillant",
				images : {
					full : '/assets/images/colors/calcata-brillant/Calcata-Brillant.jpg',
					thumb : '/assets/images/colors/calcata-brillant/Calcata-Brillant-thumb.jpg'
				},
				families : ['marbre'],
				description : "Alternative au carrare simple, pour un marbre plus lumineux.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				} 
			},
			{
				id : '0013',
				name : "Tinos",
				images : {
					full : '/assets/images/colors/tinos/Tinos.jpg',
					thumb : '/assets/images/colors/tinos/Tinos-thumb.jpg'
				},
				families : ['marbre'],
				description : "Partez explorer les îles grecques avec ce marbre blanc.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				} 
			},
			{
				id : '0014',
				name : "Paros",
				images : {
					full : '/assets/images/colors/paros/Paros.jpg',
					thumb : '/assets/images/colors/paros/Paros-thumb.jpg'
				},
				families : ['marbre'],
				description : "Un marbre gris, symbole de raffinement et d’élégance.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				}
			},
			{
				id : '0015',
				name : "Gemma",
				images : {
					full : '/assets/images/colors/gemma/Gemma.jpg',
					thumb : '/assets/images/colors/gemma/Gemma-thumb.jpg'
				},
				families : ['marbre'],
				description : "Les détails raffinés de ce marbre évoquent l'élégance des thermes et une sophistication intemporelle.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				}
			},
			{
				id : '0016',
				name : "Néro",
				images : {
					full : '/assets/images/colors/nero/Nero.jpg',
					thumb : '/assets/images/colors/nero/Nero-thumb.jpg'
				},
				families : ['marbre'],
				description : "Un marbre noir, symbole de force et de luxe.",
				txtColorLight: true,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				} 
			},
			{
				id : '0017',
				name : "Agata",
				images : {
					full : '/assets/images/colors/agata/Agata.jpg',
					thumb : '/assets/images/colors/agata/Agata-thumb.jpg'
				},
				families : ['marbre'],
				description : "L'aspect royal de ce marbre crée une ambiance luxueuse et sophistiquée.",
				txtColorLight: true,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				} 
			},
			{
				id : '0018',
				name : "Marbre royal",
				images : {
					full : '/assets/images/colors/marbre-royal/Marbre-Royal.jpg',
					thumb : '/assets/images/colors/marbre-royal/Marbre-Royal-thumb.jpg'
				},
				families : ['marbre'],
				description : "Le marbre royal nous enveloppe dans ses tons chauds, créant une atmosphère douce et enveloppante.",
				txtColorLight: true,
				supports: {
					wall: ['900x2500 • 1200x2500 • 1400x2500']
					// bati: [],
					// bath: [],
					// credenza: []
				}
			},
			{
				id : '0019',
				name : "Beton primo",
				images : {
					full : '/assets/images/colors/beton-primo/Beton-Primo.jpg',
					thumb : '/assets/images/colors/beton-primo/Beton-Primo-thumb.jpg'
				},
				families : ['loft'],
				description : "Caractère unique et authentique, s'harmonisera parfaitement avec un style moderne ou design.",
				txtColorLight: true,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				} 
			},
			{
				id : '0020',
				name : "Beton silvero",
				images : {
					full : '/assets/images/colors/beton-silvero/Beton-Silvero.jpg',
					thumb : '/assets/images/colors/beton-silvero/Beton-Silvero-thumb.jpg'
				},
				families : ['loft'],
				description : "Style moderne et élégant apportant une touche de sophistication, de fraîcheur et de lumière.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				} 
			},
			{
				id : '0021',
				name : "Ciment clair",
				images : {
					full : '/assets/images/colors/ciment-clair/Ciment-Clair.jpg',
					thumb : '/assets/images/colors/ciment-clair/Ciment-Clair-thumb.jpg'
				},
				families : ['loft'],
				description : "La force du ciment associée à une teinte paisible et discrète.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				} 
			},
			{
				id : '0022',
				name : "Béton cendro",
				images : {
					full : '/assets/images/colors/beton-cendro/Beton-Cendro.jpg',
					thumb : '/assets/images/colors/beton-cendro/Beton-Cendro-thumb.jpg'
				},
				families : ['loft'],
				description : "Inspiré du style urbain, le béton cendro apporte à votre salle de bain une ambiance industrielle moderne et authentique.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				} 
			},
			{
				id : '0023',
				name : "Corten",
				images : {
					full : '/assets/images/colors/corten/Corten.jpg',
					thumb : '/assets/images/colors/corten/Corten-thumb.jpg'
				},
				families : ['loft'],
				description : "Un style trashwall pour une salle de bain unique.",
				txtColorLight: true,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				}
			},
			{
				id : '0024',
				name : "Cuivre",
				images : {
					full : '/assets/images/colors/cuivre/Cuivre.jpg',
					thumb : '/assets/images/colors/cuivre/Cuivre-thumb.jpg'
				},
				families : ['loft'],
				description : "Une couleur vibrante et chaleureuse attire l'œil et crée une ambiance urbaine et industrielle.",
				txtColorLight: true,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				}
			},
			{
				id : '0025',
				name : "Béton brut",
				images : {
					full : '/assets/images/colors/beton-brut/Beton-Brut.jpg',
					thumb : '/assets/images/colors/beton-brut/Beton-Brut-thumb.jpg'
				},
				families : ['loft'],
				description : "Ce coloris sobre et sophistiqué vous transportera dans une atmosphère pleine de charme.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				} 
			},
			{
				id : '0026',
				name : "Béton ciré",
				images : {
					full : '/assets/images/colors/beton-cire/Beton-Cire.jpg',
					thumb : '/assets/images/colors/beton-cire/Beton-Cire-thumb.jpg'
				},
				families : ['loft'],
				description : "Alternative au béton brut, un ton plus foncé pour une douche moderne et tendance.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				} 
			},
			{
				id : '0027',
				name : "Béton charbon",
				images : {
					full : '/assets/images/colors/beton-charbon/Beton-Charbon.jpg',
					thumb : '/assets/images/colors/beton-charbon/Beton-Charbon-thumb.jpg'
				},
				families : ['loft'],
				description : "L'aspect sombre du béton apporte une touche chic et raffinée à votre salle de bain.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				} 
			},
			{
				id : '0028',
				name : "Métal brossé",
				images : {
					full : '/assets/images/colors/metal-brosse/Metal-Brosse.jpg',
					thumb : '/assets/images/colors/metal-brosse/Metal-Brosse-thumb.jpg'
				},
				families : ['loft'],
				description : "Une touche d'éclat métallique moderne pour votre intérieur.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				} 
			},
			{
				id : '0029',
				name : "Tasseaux fins",
				images : {
					full : '/assets/images/colors/tasseaux-fins/Tasseaux-Fins.jpg',
					thumb : '/assets/images/colors/tasseaux-fins/Tasseaux-Fins-thumb.jpg'
				},
				families : ['madera'],
				description : "Une version du tasseau plus fine et plus discrète apportant une touche de chaleur et d'élégance.",
				txtColorLight: true,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				} 
			},
			{
				id : '0030',
				name : "Tilleul",
				images : {
					full : '/assets/images/colors/tilleul/Tilleul.jpg',
					thumb : '/assets/images/colors/tilleul/Tilleul-thumb.jpg'
				},
				families : ['madera'],
				description : "S'harmonise facilement à de nombreux style de décoration grâce à sa teinte clair, douce et apaisante.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				} 
			},
			{
				id : '0031',
				name : "Polaire",
				images : {
					full : '/assets/images/colors/polaire/Polaire.jpg',
					thumb : '/assets/images/colors/polaire/Polaire-thumb.jpg'
				},
				families : ['madera'],
				description : "Un bois blanc qui apportera chic et sobriété à votre salle de bain.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				} 
			},
			{
				id : '0032',
				name : "Chêne",
				images : {
					full : '/assets/images/colors/chene/Chene.jpg',
					thumb : '/assets/images/colors/chene/Chene-thumb.jpg'
				},
				families : ['madera'],
				description : "La chaleur et la beauté intemporelle de la forêt sont capturées dans une couleur accueillante et lumineuse.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				}
			},
			{
				id : '0033',
				name : "Cottage",
				images : {
					full : '/assets/images/colors/cottage/Cottage.jpg',
					thumb : '/assets/images/colors/cottage/Cottage-thumb.jpg'
				},
				families : ['madera'],
				description : "Bois légèrement foncé, optez pour une texture réaliste.",
				txtColorLight: true,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				} 
			},
			{
				id : '0034',
				name : "Tasseaux horizontal",
				images : {
					full : '/assets/images/colors/tasseaux-horizontal/Tasseaux-Horizontal.jpg',
					thumb : '/assets/images/colors/tasseaux-horizontal/Tasseaux-Horizontal-thumb.jpg'
				},
				families : ['madera'],
				description : "Des lignes horizontales et chaleureuses pour un look épuré et tendance.",
				txtColorLight: true,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				} 
			},
			{
				id : '0035',
				name : "Tasseaux vertical",
				images : {
					full : '/assets/images/colors/tasseaux-vertical/Tasseaux-Vertical.jpg',
					thumb : '/assets/images/colors/tasseaux-vertical/Tasseaux-Vertical-thumb.jpg'
				},
				families : ['madera'],
				description : "Le tasseau vertical est un décor chaleureux de bois brut, apportant une touche rustique à votre décoration.",
				txtColorLight: true,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				} 
			},
			{
				id : '0036',
				name : "Orme",
				images : {
					full : '/assets/images/colors/orme/Orme.jpg',
					thumb : '/assets/images/colors/orme/Orme-thumb.jpg'
				},
				families : ['madera'],
				description : "La teinte chaleureuse de l'orme nous transporte instantanément dans l'ambiance réconfortante de l'hiver, blottis près de la cheminée.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				} 
			},
			{
				id : '0037',
				name : "Grege",
				images : {
					full : '/assets/images/colors/grege/Grege.jpg',
					thumb : '/assets/images/colors/grege/Grege-thumb.jpg'
				},
				families : ['unis'],
				description : "Une teinte douce et subtile qui combine les aspects apaisants et terreux du beige avec les qualités modernes et sophistiquées du gris.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				}
			},
			{
				id : '0038',
				name : "Bleu persan",
				images : {
					full : '/assets/images/colors/bleu-persan/Bleu-Persan.jpg',
					thumb : '/assets/images/colors/bleu-persan/Bleu-Persan-thumb.jpg'
				},
				families : ['unis'],
				description : "Le bleu persan, également connu sous le nom de bleu Perse, est une teinte riche et profonde qui évoque le luxe et l'élégance.",
				txtColorLight: true,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				}
			},
			{
				id : '0039',
				name : "Lin",
				images : {
					full : '/assets/images/colors/lin/Lin.png',
					thumb : '/assets/images/colors/lin/Lin-thumb.png'
				},
				families : ['unis'],
				description : "Cette teinte douce a l’avantage de se mixer avec tout !",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				}
			},
			{
				id : '0040',
				name : "Douce patine",
				images : {
					full : '/assets/images/colors/douce-patine/Douce-Patine.jpg',
					thumb : '/assets/images/colors/douce-patine/Douce-Patine-thumb.jpg'
				},
				families : ['unis'],
				description : "La couleur douce patine apporte une atmosphère apaisante et chaleureuse créant un espace de détente et de calme.",
				txtColorLight: false,
				supports: {
					wall: ['900x2500 • 1200x2500 • 1400x2500']//,
					// bati: [],
					// bath: [],
					// credenza: []
				}
			},
			{
				id : '0041',
				name : "Moutarde",
				images : {
					full : '/assets/images/colors/moutarde/Moutarde.jpg',
					thumb : '/assets/images/colors/moutarde/Moutarde-thumb.jpg'
				},
				families : ['unis'],
				description : "La couleur moutarde apporte une touche d'élégance vive et chaleureuse.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				}
			},
			{
				id : '0042',
				name : "Terracotta",
				images : {
					full : '/assets/images/colors/terracotta/Terracotta.png',
					thumb : '/assets/images/colors/terracotta/Terracotta-thumb.png'
				},
				families : ['unis'],
				description : "Chaleur assurée avec ce ton très tendance.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				}
			},
			{
				id : '0043',
				name : "Rose poudré",
				images : {
					full : '/assets/images/colors/rose-poudre/Rose-Poudre.jpg',
					thumb : '/assets/images/colors/rose-poudre/Rose-Poudre-thumb.jpg'
				},
				families : ['unis'],
				description : "Une teinte délicate et apaisante, évoquant la douceur et la tendresse.",
				txtColorLight: false,
				supports: {
					wall: ['900x2500 • 1200x2500 • 1400x2500']//,
					// bati: [],
					// bath: [],
					// credenza: []
				}
			},
			{
				id : '0044',
				name : "Terre cuite",
				images : {
					full : '/assets/images/colors/terre-cuite/Terre-Cuite.jpg',
					thumb : '/assets/images/colors/terre-cuite/Terre-Cuite-thumb.jpg'
				},
				families : ['unis'],
				description : "La terre cuite évoque une esthétique chaleureuse et terreuse, typique des designs traditionnels et rustiques.",
				txtColorLight: true,
				supports: {
					wall: ['900x2500 • 1200x2500 • 1400x2500']//,
					// bati: [],
					// bath: [],
					// credenza: []
				}
			},
			{
				id : '0045',
				name : "Argila",
				images : {
					full : '/assets/images/colors/argila/Argila.jpg',
					thumb : '/assets/images/colors/argila/Argila-thumb.jpg'
				},
				families : ['unis'],
				description : "La teinte chaleureuse de l'argile rouge rappelle la couleur de la terre séchée au soleil avec ses oxydes de fer.",
				txtColorLight: true,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400']
				}
			},
			{
				id : '0046',
				name : "Parme",
				images : {
					full : '/assets/images/colors/parme/Parme.png',
					thumb : '/assets/images/colors/parme/Parme-thumb.png'
				},
				families : ['unis'],
				description : "Un ton rassurant, calme et apaisant.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				}
			},
			{
				id : '0047',
				name : "Taupe",
				images : {
					full : '/assets/images/colors/taupe/Taupe.png',
					thumb : '/assets/images/colors/taupe/Taupe-thumb.png'
				},
				families : ['unis'],
				description : "Cette teinte unie dégage calme et chaleur.",
				txtColorLight: true,
				supports: {
					wall: ['900x2000'],
					bati: [],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				} 
			},
			{
				id : '0048',
				name : "Céladon",
				images : {
					full : '/assets/images/colors/celadon/Celadon.jpg',
					thumb : '/assets/images/colors/celadon/Celadon-thumb.jpg'
				},
				families : ['unis'],
				description : "Le céladon est une teinte délicate et rafraîchissante qui évoque la sérénité et la fraîcheur d'une journée printanière.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				} 
			},
			{
				id : '0049',
				name : "Vert impérial",
				images : {
					full : '/assets/images/colors/vert-imperial/Vert-Imperial.jpg',
					thumb : '/assets/images/colors/vert-imperial/Vert-Imperial-thumb.jpg'
				},
				families : ['unis'],
				description : "Une nuance de vert profond et moderne.",
				txtColorLight: true,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				}
			},
			{
				id : '0050',
				name : "Océan",
				images : {
					full : '/assets/images/colors/ocean/Ocean.jpg',
					thumb : '/assets/images/colors/ocean/Ocean-thumb.jpg'
				},
				families : ['unis'],
				description : "La couleur profonde de l'océan inspire à la fois la tranquillité et la puissance de la nature.",
				txtColorLight: true,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				} 
			},
			{
				id : '0051',
				name : "Bleu profond",
				images : {
					full : '/assets/images/colors/bleu-profond/Bleu-Profond.png',
					thumb : '/assets/images/colors/bleu-profond/Bleu-Profond-thumb.png'
				},
				families : ['unis'],
				description : "Plongez dans les abysses avec ce bleu !",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				}
			},
			{
				id : '0052',
				name : "Bleu majorelle",
				images : {
					full : '/assets/images/colors/bleu-majorelle/Bleu-Majorelle.jpg',
					thumb : '/assets/images/colors/bleu-majorelle/Bleu-Majorelle-thumb.jpg'
				},
				families : ['unis'],
				description : "Un bleu vif et intense, rappelant la couleur des murs d’un jardin marocain.",
				txtColorLight: true,
				supports: {
					wall: ['900x2500 • 1200x2500 • 1400x2500']//,
					// bati: [],
					// bath: [],
					// credenza: []
				}
			},
			{
				id : '0053',
				name : "Travertin clair",
				images : {
					full : '/assets/images/colors/travertin-clair/Travertin-Clair.jpg',
					thumb : '/assets/images/colors/travertin-clair/Travertin-Clair-thumb.jpg'
				},
				families : ['mineralis'],
				description : "Apporte une touche de sophistication discrète et de naturalité à tout espace, créant une ambiance chaleureuse et accueillante.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				}
			},
			{
				id : '0054',
				name : "Sepia",
				images : {
					full : '/assets/images/colors/sepia/Sepia.jpg',
					thumb : '/assets/images/colors/sepia/Sepia-thumb.jpg'
				},
				families : ['mineralis'],
				description : "Une teinte intemporelle qui apportera chaleur et lumière à votre espace.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				} 
			},
			{
				id : '0055',
				name : "Pierre du Portugal",
				images : {
					full : '/assets/images/colors/pierre-du-portugal/Pierre-du-Portugal.jpg',
					thumb : '/assets/images/colors/pierre-du-portugal/Pierre-du-Portugal-thumb.jpg'
				},
				families : ['mineralis'],
				description : "Une touche de sobriété pour un espace harmonieux et reposant.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				} 
			},
			{
				id : '0056',
				name : "Minéral",
				images : {
					full : '/assets/images/colors/mineral/Mineral.jpg',
					thumb : '/assets/images/colors/mineral/Mineral-thumb.jpg'
				},
				families : ['mineralis'],
				description : "Votre intérieur n’échappera pas à la force graphique de la pierre.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				} 
			},
			{
				id : '0057',
				name : "Opale",
				images : {
					full : '/assets/images/colors/opale/Opale.jpg',
					thumb : '/assets/images/colors/opale/Opale-thumb.jpg'
				},
				families : ['mineralis'],
				description : "Une pierre précieuse aux reflets irisés, symbole de la sérénité et de la spiritualité.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				}
			},
			{
				id : '0058',
				name : "Pierre du Nil",
				images : {
					full : '/assets/images/colors/pierre-du-nil/Pierre-Du-Nil.jpg',
					thumb : '/assets/images/colors/pierre-du-nil/Pierre-Du-Nil-thumb.jpg'
				},
				families : ['mineralis'],
				description : "Une pierre naturelle évoquant les teintes dorées du sable au bord du fleuve de renom.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				}
			},
			{
				id : '0059',
				name : "Pierre de lune",
				images : {
					full : '/assets/images/colors/pierre-de-lune/Pierre-De-Lune.jpg',
					thumb : '/assets/images/colors/pierre-de-lune/Pierre-De-Lune-thumb.jpg'
				},
				families : ['mineralis'],
				description : "Un effet « plâtre » à l’aspect lunaire.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				}
			},
			{
				id : '0060',
				name : "Granit",
				images : {
					full : '/assets/images/colors/granit/Granit.jpg',
					thumb : '/assets/images/colors/granit/Granit-thumb.jpg'
				},
				families : ['mineralis'],
				description : "Une pierre naturelle évoquant la force et la durabilité.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				}
			},
			{
				id : '0061',
				name : "Chevron",
				images : {
					full : '/assets/images/colors/chevron/Chevron.jpg',
					thumb : '/assets/images/colors/chevron/Chevron-thumb.jpg'
				},
				families : ['artisanal'],
				description : "La sobriété des tons clairs alliée à l’effet de rythme graphique.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400']
				}
			},
			{
				id : '0062',
				name : "Carreaux ciment beige",
				images : {
					full : '/assets/images/colors/carreaux-ciment-beige/Carreaux-Ciment-Beige.jpg',
					thumb : '/assets/images/colors/carreaux-ciment-beige/Carreaux-Ciment-Beige-thumb.jpg'
				},
				families : ['artisanal'],
				description : "Des carreaux qui apportent une touche élégante et intemporelle à votre intérieur.",
				txtColorLight: false,
				supports: {
					wall: ['900x2500 • 1200x2500 • 1400x2500']//,
					// bati: [],
					// bath: [],
					// credenza: []
				}
			},
			{
				id : '0063',
				name : "Écailles pearl",
				images : {
					full : '/assets/images/colors/ecailles-pearl/Ecailles-Pearl.jpg',
					thumb : '/assets/images/colors/ecailles-pearl/Ecailles-Pearl-thumb.jpg'
				},
				families : ['artisanal'],
				description : "C’est une nuance subtile et irisée évoquant l'éclat des perles et des coquillages.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400']
				}
			},
			{
				id : '0064',
				name : "Écailles denim",
				images : {
					full : '/assets/images/colors/ecailles-denim/Ecailles-Denim.jpg',
					thumb : '/assets/images/colors/ecailles-denim/Ecailles-Denim-thumb.jpg'
				},
				families : ['artisanal'],
				description : "Des carreaux en écailles d’un bleu clair rappelant la teinte d'un jean vintage.",
				txtColorLight: true,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400']
				}
			},
			{
				id : '0065',
				name : "Stria",
				images : {
					full : '/assets/images/colors/stria/Stria.jpg',
					thumb : '/assets/images/colors/stria/Stria-thumb.jpg'
				},
				families : ['artisanal'],
				description : "Ce décor apporte une touche de subtilité et d'élégance, par un ton clair et lumineux.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400']
				}
			},
			{
				id : '0066',
				name : "Empreinte",
				images : {
					full : '/assets/images/colors/empreinte/Empreinte.jpg',
					thumb : '/assets/images/colors/empreinte/Empreinte-thumb.jpg'
				},
				families : ['vegetalis'],
				description : "Un décor végétal subtil et apaisant qui vous plongera dans un univers naturel et authentique.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400']
				}
			},

			{
				id : '0067',
				name : "Ventana",
				images : {
					full : '/assets/images/colors/ventana/Ventana.jpg',
					thumb : '/assets/images/colors/ventana/Ventana-thumb.jpg'
				},
				families : ['vegetalis'],
				description : "Des teintes lumineuses, claires et aériennes rappelant la végétation visible par une fenêtre.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400']
				}
			},

			{
				id : '0068',
				name : "Palmera",
				images : {
					full : '/assets/images/colors/palmera/Palmera.jpg',
					thumb : '/assets/images/colors/palmera/Palmera-thumb.jpg'
				},
				families : ['vegetalis'],
				description : "Un décor qui inspire la nature, la fraîcheur et la sérénité… Idéal pour une ambiance tropicale !",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400']
				}
			},
			{
				id : '0069',
				name : "Tropical",
				images : {
					full : '/assets/images/colors/tropical/Tropical.jpg',
					thumb : '/assets/images/colors/tropical/Tropical-thumb.jpg'
				},
				families : ['vegetalis'],
				description : "Ce décor végétal plein d'exotisme rappelle les paysages vibrants des tropiques.",
				txtColorLight: true,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400']
				}
			},
			{
				id : '0070',
				name : "Floralia",
				images : {
					full : '/assets/images/colors/floralia/Floralia.jpg',
					thumb : '/assets/images/colors/floralia/Floralia-thumb.jpg'
				},
				families : ['vegetalis'],
				description : "Un design doux et de lumineux évoquant la délicatesse et l'élégance des fleurs printanières.",
				txtColorLight: true,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400']
				}
			},
			{
				id : '0071',
				name : "Amazonia",
				images : {
					full : '/assets/images/colors/amazonia/Amazonia.jpg',
					thumb : '/assets/images/colors/amazonia/Amazonia-thumb.jpg'
				},
				families : ['vegetalis'],
				description : "Une surperposition de feuilles de la teinte de vert impérial, pensez au mix & match !",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400']
				}
			},
			{
				id : '0072',
				name : "Ginkgo",
				images : {
					full : '/assets/images/colors/ginkgo/Ginkgo.jpg',
					thumb : '/assets/images/colors/ginkgo/Ginkgo-thumb.jpg'
				},
				families : ['vegetalis'],
				description : "Le ginkgo est un arbre qui incarne la persévérance, l'espoir et la résilience.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400']
				}
			},
			{
				id : '0073',
				name : "Paolo",
				images : {
					full : '/assets/images/colors/paolo/Paolo.jpg',
					thumb : '/assets/images/colors/paolo/Paolo-thumb.jpg'
				},
				families : ['artdec'],
				description : "Un vert émeraude vibrant et rafraîchissant, teinté d'une touche d'élégance.",
				txtColorLight: true,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400']
				}
			},
			{
				id : '0074',
				name : "Sanjana",
				images : {
					full : '/assets/images/colors/sanjana/Sanjana.jpg',
					thumb : '/assets/images/colors/sanjana/Sanjana-thumb.jpg'
				},
				families : ['artdec'],
				description : "Sanjana a été créé avec le fond « bleu profond », habillé de formes dorées élégantes et sophistiquées. Pensez au mix & match !",
				txtColorLight: true,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400']
				}
			},
			{
				id : '0075',
				name : "Hazel",
				images : {
					full : '/assets/images/colors/hazel/Hazel.jpg',
					thumb : '/assets/images/colors/hazel/Hazel-thumb.jpg'
				},
				families : ['artdec'],
				description : "La couleur hazel est un mélange fascinant de noir et de doré sur un fond blanc marbré, créant un décor chaud et captivant.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400']
				}
			},
			{
				id : '0076',
				name : "Leandro",
				images : {
					full : '/assets/images/colors/leandro/Leandro.jpg',
					thumb : '/assets/images/colors/leandro/Leandro-thumb.jpg'
				},
				families : ['artdec'],
				description : "Ce décor, très tendance Art Déco, mêle l’originalité à l’élégance.",
				txtColorLight: true,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400']
				}
			},
			{
				id : '0077',
				name : "Majora",
				images : {
					full : '/assets/images/colors/majora/Majora.jpg',
					thumb : '/assets/images/colors/majora/Majora-thumb.jpg'
				},
				families : ['artdec'],
				description : "C'est une teinte taupe subtilement raffinée accompagnée de motifs dorés élégants qui ajoutent une touche sophistiquée.",
				txtColorLight: true,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400']
				}
			},
			{
				id : '0078',
				name : "Belle époque",
				images : {
					full : '/assets/images/colors/belle-epoque/Belle-Epoque.png',
					thumb : '/assets/images/colors/belle-epoque/Belle-Epoque-thumb.jpg'
				},
				families : ['artdec'],
				description : "Osez ce décor digne d’un chef d’œuvre contemporain.",
				txtColorLight: true,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400']
				}
			},
			{
				id : '0079',
				name : "Triptyque printemps gauche",
				images : {
					full : '/assets/images/colors/triptyque-printemps-gauche/Triptyque-Printemps-Gauche.jpg',
					thumb : '/assets/images/colors/triptyque-printemps-gauche/Triptyque-Printemps-Gauche-thumb.jpg'
				},
				families : ['panoramique'],
				description : "Ensemble de 3 décors formant une composition florale originale, une véritable œuvre d'art murale !",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500']
					// bati: [],
					// bath: [],
					// credenza: []
				}
			},
			{
				id : '0080',
				name : "Triptyque printemps centre",
				images : {
					full : '/assets/images/colors/triptyque-printemps-centre/Triptyque-Printemps-Centre.jpg',
					thumb : '/assets/images/colors/triptyque-printemps-centre/Triptyque-Printemps-Centre-thumb.jpg'
				},
				families : ['panoramique'],
				description : "Ensemble de 3 décors formant une composition florale originale, une véritable œuvre d'art murale !",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500']
					// bati: [],
					// bath: [],
					// credenza: []
				}
			},
			{
				id : '0081',
				name : "Triptyque printemps droite",
				images : {
					full : '/assets/images/colors/triptyque-printemps-droite/Triptyque-Printemps-Droite.jpg',
					thumb : '/assets/images/colors/triptyque-printemps-droite/Triptyque-Printemps-Droite-thumb.jpg'
				},
				families : ['panoramique'],
				description : "Ensemble de 3 décors formant une composition florale originale, une véritable œuvre d'art murale !",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500']
					// bati: [],
					// bath: [],
					// credenza: []
				}
			},
			{
				id : '0082',
				name : "Diptyque geometrique gauche",
				images : {
					full : '/assets/images/colors/diptyque-geometrique-gauche/Diptyque-Geometrique-Gauche.jpg',
					thumb : '/assets/images/colors/diptyque-geometrique-gauche/Diptyque-Geometrique-Gauche-thumb.jpg'
				},
				families : ['panoramique'],
				description : "Deux décors placés côte à côte, des formes géométriques et des couleurs pastelles pour une ambiance douce.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500']
					// bati: [],
					// bath: [],
					// credenza: []
				}
			},
			{
				id : '0083',
				name : "Diptyque geometrique droite",
				images : {
					full : '/assets/images/colors/diptyque-geometrique-droite/Diptyque-Geometrique-Droite.jpg',
					thumb : '/assets/images/colors/diptyque-geometrique-droite/Diptyque-Geometrique-Droite-thumb.jpg'
				},
				families : ['panoramique'],
				description : "Deux décors placés côte à côte, des formes géométriques et des couleurs pastelles pour une ambiance douce.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500']
					// bati: [],
					// bath: [],
					// credenza: []
				}
			},
			{
				id : '0084',
				name : "Cascade gauche",
				images : {
					full : '/assets/images/colors/triptyque-cascade-gauche/Triptyque-Cascade-Gauche.jpg',
					thumb : '/assets/images/colors/triptyque-cascade-gauche/Triptyque-Cascade-Gauche-thumb.jpg'
				},
				families : ['panoramique'],
				description : "Ce décor saisissant, sous forme de diptyque ou de triptyque, capture une chute d’eau au cœur de la nature au trait de crayon.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500']
					// bati: [],
					// bath: [],
					// credenza: []
				}
			},
			{
				id : '0085',
				name : "Cascade centre",
				images : {
					full : '/assets/images/colors/triptyque-cascade-centre/Triptyque-Cascade-Centre.jpg',
					thumb : '/assets/images/colors/triptyque-cascade-centre/Triptyque-Cascade-Centre-thumb.jpg'
				},
				families : ['panoramique'],
				description : "Ce décor saisissant, sous forme de diptyque ou de triptyque, capture une chute d’eau au cœur de la nature au trait de crayon.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500']
					// bati: [],
					// bath: [],
					// credenza: []
				}
			},
			{
				id : '0086',
				name : "Cascade droite",
				images : {
					full : '/assets/images/colors/triptyque-cascade-droite/Triptyque-Cascade-Droite.jpg',
					thumb : '/assets/images/colors/triptyque-cascade-droite/Triptyque-Cascade-Droite-thumb.jpg'
				},
				families : ['panoramique'],
				description : "Ce décor saisissant, sous forme de diptyque ou de triptyque, capture une chute d’eau au cœur de la nature au trait de crayon.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500']
					// bati: [],
					// bath: [],
					// credenza: []
				}
			},
			{
				id : '0087',
				name : "Jungle gauche",
				images : {
					full : '/assets/images/colors/triptyque-jungle-gauche/Triptyque-Jungle-Gauche.jpg',
					thumb : '/assets/images/colors/triptyque-jungle-gauche/Triptyque-Jungle-Gauche-thumb.jpg'
				},
				families : ['panoramique'],
				description : "Un décor luxuriant, évoquant les paysages tropicaux, avec des nuances de vert évoquant la nature sauvage en pleine jungle.",
				txtColorLight: true,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500']
					// bati: [],
					// bath: [],
					// credenza: []
				}
			},
			{
				id : '0088',
				name : "Jungle centre",
				images : {
					full : '/assets/images/colors/triptyque-jungle-centre/Triptyque-Jungle-Centre.jpg',
					thumb : '/assets/images/colors/triptyque-jungle-centre/Triptyque-Jungle-Centre-thumb.jpg'
				},
				families : ['panoramique'],
				description : "Un décor luxuriant, évoquant les paysages tropicaux, avec des nuances de vert évoquant la nature sauvage en pleine jungle.",
				txtColorLight: true,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500']
					// bati: [],
					// bath: [],
					// credenza: []
				}
			},
			{
				id : '0089',
				name : "Jungle droite",
				images : {
					full : '/assets/images/colors/triptyque-jungle-droite/Triptyque-Jungle-Droite.jpg',
					thumb : '/assets/images/colors/triptyque-jungle-droite/Triptyque-Jungle-Droite-thumb.jpg'
				},
				families : ['panoramique'],
				description : "Un décor luxuriant, évoquant les paysages tropicaux, avec des nuances de vert évoquant la nature sauvage en pleine jungle.",
				txtColorLight: true,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500']
					// bati: [],
					// bath: [],
					// credenza: []
				}
			},
			{
				id : '0090',
				name : "Koï gauche",
				images : {
					full : '/assets/images/colors/triptyque-koi-gauche/Triptyque-Koi-Gauche.jpg',
					thumb : '/assets/images/colors/triptyque-koi-gauche/Triptyque-Koi-Gauche-thumb.jpg'
				},
				families : ['panoramique'],
				description : "Sur un fond tadelakt, ces formes de koï blanches semblent danser dans l'eau, symbolisant la pureté et apportant un air japonisant.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500']
					// bati: [],
					// bath: [],
					// credenza: []
				}
			},
			{
				id : '0091',
				name : "Koï centre",
				images : {
					full : '/assets/images/colors/triptyque-koi-centre/Triptyque-Koi-Centre.jpg',
					thumb : '/assets/images/colors/triptyque-koi-centre/Triptyque-Koi-Centre-thumb.jpg'
				},
				families : ['panoramique'],
				description : "Sur un fond tadelakt, ces formes de koï blanches semblent danser dans l'eau, symbolisant la pureté et apportant un air japonisant.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500']
					// bati: [],
					// bath: [],
					// credenza: []
				}
			},
			{
				id : '0092',
				name : "Koï droite",
				images : {
					full : '/assets/images/colors/triptyque-koi-droite/Triptyque-Koi-Droite.jpg',
					thumb : '/assets/images/colors/triptyque-koi-droite/Triptyque-Koi-Droite-thumb.jpg'
				},
				families : ['panoramique'],
				description : "Sur un fond tadelakt, ces formes de koï blanches semblent danser dans l'eau, symbolisant la pureté et apportant un air japonisant.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500']
					// bati: [],
					// bath: [],
					// credenza: []
				}
			},
			{
				id : '0093',
				name : "Arche terre cuite",
				images : {
					full : '/assets/images/colors/arche-terre-cuite/Arche-Terre-Cuite.jpg',
					thumb : '/assets/images/colors/arche-terre-cuite/Arche-Terre-Cuite-thumb.jpg'
				},
				families : ['a-la-bastide'],
				description : "Une arche aux notes de terre cuite et de rose boudoir. Ce décor donnera de la rondeur à votre salle de bain.",
				txtColorLight: true,
				supports: {
					wall: ['900x2500 • 1200x2500 • 1400x2500']
					// bati: [],
					// bath: [],
					// credenza: []
				}
			},
			{
				id : '0094',
				name : "Carreaux ciment beige",
				images : {
					full : '/assets/images/colors/carreaux-ciment-beige/Carreaux-Ciment-Beige.jpg',
					thumb : '/assets/images/colors/carreaux-ciment-beige/Carreaux-Ciment-Beige-thumb.jpg'
				},
				families : ['a-la-bastide'],
				description : "Ce design apporte une touche élégante et intemporelle à votre intérieur.",
				txtColorLight: false,
				supports: {
					wall: ['900x2500 • 1200x2500 • 1400x2500']
					// bati: [],
					// bath: [],
					// credenza: []
				}
			},
			{
				id : '0095',
				name : "Douce patine",
				images : {
					full : '/assets/images/colors/douce-patine/Douce-Patine.jpg',
					thumb : '/assets/images/colors/douce-patine/Douce-Patine-thumb.jpg'
				},
				families : ['a-la-bastide'],
				description : "La couleur douce patine apporte une atmosphère apaisante et chaleureuse créant un espace de détente et de calme.",
				txtColorLight: false,
				supports: {
					wall: ['900x2500 • 1200x2500 • 1400x2500']
					// bati: [],
					// bath: [],
					// credenza: []
				}
			},
			{
				id : '0096',
				name : "Marbre royal",
				images : {
					full : '/assets/images/colors/marbre-royal/Marbre-Royal.jpg',
					thumb : '/assets/images/colors/marbre-royal/Marbre-Royal-thumb.jpg'
				},
				families : ['a-la-bastide'],
				description : "Le marbre royal nous enveloppe dans ses tons chauds, créant une atmosphère douce et enveloppante.",
				txtColorLight: true,
				supports: {
					wall: ['900x2500 • 1200x2500 • 1400x2500']
					// bati: [],
					// bath: [],
					// credenza: []
				}
			},
			{
				id : '0097',
				name : "Mix ciment listel",
				images : {
					full : '/assets/images/colors/mix-ciment-listel/Mix-Ciment-Listel.jpg',
					thumb : '/assets/images/colors/mix-ciment-listel/Mix-Ciment-Listel-thumb.jpg'
				},
				families : ['a-la-bastide'],
				description : "Le mélange de carreau et de ciment clair apporte une touche de caractère et d'authenticité.",
				txtColorLight: false,
				supports: {
					wall: ['900x2500 • 1200x2500 • 1400x2500']
					// bati: [],
					// bath: [],
					// credenza: []
				}
			},
			{
				id : '0098',
				name : "Triptyque gauche",
				images : {
					full : '/assets/images/colors/diptyque-gauche/Triptyque-Gauche.jpg',
					thumb : '/assets/images/colors/diptyque-gauche/Triptyque-Gauche-thumb.jpg'
				},
				families : ['a-la-bastide'],
				description : "Un panneau à installer seul ou à associer en triptyque. Le marbre royal associé au mélange de carreau et de ciment clair crée une atmosphère à la fois élégante et intemporelle.",
				txtColorLight: false,
				supports: {
					wall: ['900x2500 • 1200x2500 • 1400x2500']
					// bati: [],
					// bath: [],
					// credenza: []
				}
			},
			{
				id : '0099',
				name : "Triptyque centre",
				images : {
					full : '/assets/images/colors/diptyque-centre/Triptyque-Centre.jpg',
					thumb : '/assets/images/colors/diptyque-centre/Triptyque-Centre-thumb.jpg'
				},
				families : ['a-la-bastide'],
				description : "Un panneau à installer seul ou à associer en triptyque. Le marbre royal associé au mélange de carreau et de ciment clair crée une atmosphère à la fois élégante et intemporelle.",
				txtColorLight: false,
				supports: {
					wall: ['900x2500 • 1200x2500 • 1400x2500']
					// bati: [],
					// bath: [],
					// credenza: []
				}
			},
			{
				id : '0100',
				name : "Triptyque droit",
				images : {
					full : '/assets/images/colors/diptyque-droit/Triptyque-Droit.jpg',
					thumb : '/assets/images/colors/diptyque-droit/Triptyque-Droit-thumb.jpg'
				},
				families : ['a-la-bastide'],
				description : "Un panneau à installer seul ou à associer en triptyque. Le marbre royal associé au mélange de carreau et de ciment clair crée une atmosphère à la fois élégante et intemporelle.",
				txtColorLight: false,
				supports: {
					wall: ['900x2500 • 1200x2500 • 1400x2500']
					// bati: [],
					// bath: [],
					// credenza: []
				}
			},
			{
				id : '0101',
				name : "Bleu mirage",
				images : {
					full : '/assets/images/colors/bleu-mirage/Bleu-Mirage.jpg',
					thumb : '/assets/images/colors/bleu-mirage/Bleu-Mirage-thumb.jpg'
				},
				families : ['a-la-bastide'],
				description : "Le bleu mirage est une nuance profonde et intense, aux reflets mystérieux et envoûtants.",
				txtColorLight: true,
				supports: {
					wall: ['900x2500 • 1200x2500 • 1400x2500']
					// bati: [],
					// bath: [],
					// credenza: []
				}
			},
			{
				id : '0102',
				name : "Bleu majorelle",
				images : {
					full : '/assets/images/colors/bleu-majorelle/Bleu-Majorelle.jpg',
					thumb : '/assets/images/colors/bleu-majorelle/Bleu-Majorelle-thumb.jpg'
				},
				families : ['a-la-bastide'],
				description : "Un bleu vif et intense, rappelant la couleur des murs d’un jardin marocain.",
				txtColorLight: true,
				supports: {
					wall: ['900x2500 • 1200x2500 • 1400x2500']
					// bati: [],
					// bath: [],
					// credenza: []
				}
			},
			{
				id : '0103',
				name : "Tadelakt cuir",
				images : {
					full : '/assets/images/colors/arche-tadelackt-cuir/Arche-Tadelackt-Cuir.jpg',
					thumb : '/assets/images/colors/arche-tadelackt-cuir/Arche-Tadelackt-Cuir-thumb.jpg'
				},
				families : ['vallon-des-auffes'],
				description : "Le Tadelakt cuir est une couleur chaleureuse et raffinée, inspirée par le savoir-faire marocain.",
				txtColorLight: false,
				supports: {
					wall: ['900x2500 • 1200x2500 • 1400x2500']
					// bati: [],
					// bath: [],
					// credenza: []
				}
			},
			{
				id : '0104',
				name : "Arche Tadelakt sable gauche",
				images : {
					full : '/assets/images/colors/arche-tadelackt-sable-gauche/Arche-Tadelackt-Sable-Gauche.jpg',
					thumb : '/assets/images/colors/arche-tadelackt-sable-gauche/Arche-Tadelackt-Sable-Gauche-thumb.jpg'
				},
				families : ['vallon-des-auffes'],
				description : "La teinte tadelakt sable de l'arche est un mélange de beige et de brun évoquant la chaleur des plages ensoleillées de la méditerrannée.",
				txtColorLight: false,
				supports: {
					wall: ['900x2500 • 1200x2500 • 1400x2500']
					// bati: [],
					// bath: [],
					// credenza: []
				}
			},
			{
				id : '0105',
				name : "Arche Tadelakt sable droite",
				images : {
					full : '/assets/images/colors/arche-tadelackt-sable-droite/Arche-Tadelackt-Sable-Droite.jpg',
					thumb : '/assets/images/colors/arche-tadelackt-sable-droite/Arche-Tadelackt-Sable-Droite-thumb.jpg'
				},
				families : ['vallon-des-auffes'],
				description : "La teinte tadelakt sable de l'arche est un mélange de beige et de brun évoquant la chaleur des plages ensoleillées de la méditerrannée.",
				txtColorLight: false,
				supports: {
					wall: ['900x2500 • 1200x2500 • 1400x2500']
					// bati: [],
					// bath: [],
					// credenza: []
				}
			},
			{
				id : '0106',
				name : "Zellige",
				images : {
					full : '/assets/images/colors/zellige/Zellige.jpg',
					thumb : '/assets/images/colors/zellige/Zellige-thumb.jpg'
				},
				families : ['vallon-des-auffes'],
				description : "Une couleur vibrante et lumineuse rappelant les nuances du vert émeraude et du bleu turquoise.",
				txtColorLight: true,
				supports: {
					wall: ['900x2500 • 1200x2500 • 1400x2500']
					// bati: [],
					// bath: [],
					// credenza: []
				}
			},
			{
				id : '0107',
				name : "Rose poudré",
				images : {
					full : '/assets/images/colors/rose-poudre/Rose-Poudre.jpg',
					thumb : '/assets/images/colors/rose-poudre/Rose-Poudre-thumb.jpg'
				},
				families : ['vallon-des-auffes'],
				description : "En créant une ambiance féérique, cette teinte nous transporte dans un monde de douceur et de rêveries.",
				txtColorLight: false,
				supports: {
					wall: ['900x2500 • 1200x2500 • 1400x2500']
					// bati: [],
					// bath: [],
					// credenza: []
				}
			},
			{
				id : '0108',
				name : "Arche Tadelakt cuir",
				images : {
					full : '/assets/images/colors/arche-tadelackt-cuir/Arche-Tadelackt-Cuir.jpg',
					thumb : '/assets/images/colors/arche-tadelackt-cuir/Arche-Tadelackt-Cuir-thumb.jpg'
				},
				families : ['vallon-des-auffes'],
				description : "Une arche se dessine pour apporter du mouvement à votre salle de bain. Sa teinte, chaleureuse et naturelle, embellit une pièce en lui apportant un côté authentique tout en restant dans la tendance actuelle.",
				txtColorLight: false,
				supports: {
					wall: ['900x2500 • 1200x2500 • 1400x2500']
					// bati: [],
					// bath: [],
					// credenza: []
				}
			},
			{
				id : '0109',
				name : "Tadelakt sable",
				images : {
					full : '/assets/images/colors/tadelackt-sable/Tadelackt-Sable.jpg',
					thumb : '/assets/images/colors/tadelackt-sable/Tadelackt-Sable-thumb.jpg'
				},
				families : ['vallon-des-auffes'],
				description : "Cette teinte chaleureuse rappelle le sable des plages ensoleillées.",
				txtColorLight: false,
				supports: {
					wall: ['900x2500 • 1200x2500 • 1400x2500']
					// bati: [],
					// bath: [],
					// credenza: []
				}
			},
			{
				id : '0110',
				name : "Terre cuite",
				images : {
					full : '/assets/images/colors/terre-cuite/Terre-Cuite.jpg',
					thumb : '/assets/images/colors/terre-cuite/Terre-Cuite-thumb.jpg'
				},
				families : ['vallon-des-auffes'],
				description : "La terre cuite évoque une esthétique chaleureuse et terreuse, typique des designs traditionnels et rustiques.",
				txtColorLight: true,
				supports: {
					wall: ['900x2500 • 1200x2500 • 1400x2500']
					// bati: [],
					// bath: [],
					// credenza: []
				}
			},
			{
				id : '0111',
				name : "Cabane",
				images : {
					full : '/assets/images/colors/cabane/Cabane.jpg',
					thumb : '/assets/images/colors/cabane/Cabane-thumb.jpg'
				},
				families : ['collector'],
				description : "Illusion réaliste d’un bois comme si vous étiez dans une cabane.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400']
				}
			},
			{
				id : '0112',
				name : "Patchwork Bleu",
				images : {
					full : '/assets/images/colors/patchwork-bleu/Patchwork-Bleu.jpg',
					thumb : '/assets/images/colors/patchwork-bleu/Patchwork-Bleu-thumb.jpg'
				},
				families : ['collector'],
				description : "Ces carreaux s’imposent avec un style d’antan.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400']
				}
			},
			{
				id : '0113',
				name : "Parement",
				images : {
					full : '/assets/images/colors/parement/Parement.jpg',
					thumb : '/assets/images/colors/parement/Parement-thumb.jpg'
				},
				families : ['collector'],
				description : "Illusion d’un mur en pierre aux reliefs charmeurs.",
				txtColorLight: true,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500 • 1400x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400']
				}
			},
			{
				id : '0114',
				name : "Tétris Noir",
				images : {
					full : '/assets/images/colors/tetris-noir/Tetris-Noir.jpg',
					thumb : '/assets/images/colors/tetris-noir/Tetris-Noir-thumb.jpg'
				},
				families : ['collector'],
				description : "Créez une ambiance assumée avec une force graphique pleine de style.",
				txtColorLight: false,
				supports: {
					wall: ['900x2000 • 1200x2000 • 900x2500', '1200x2500'],
					bati: ['1200x1500'],
					bath: ['DROIT 2000x600 • RETOUR 900x600'],
					credenza: ['1200x400'],
					// niche: ['300x300xP70 • 300x600xP70 • 300x900xP70', '600x300xP70 • 900x300xP70'],
					pipe: ['150x150x2500 • 300x300x2500', '300x300x300x2500']
				}
			}
		]
  	}

  	getColorsByFamily(family)
  	{
  		let result = [];
  		for (let color of this.data ) {
  			if ( color.families.indexOf(family) != -1 ) {
  				result.push(color)
  			}
  		}
  		return result;
  	}


  	getColorById(id)
  	{
		for ( let color of this.data ) {
  			if ( color.id == id ) {
  				return color;
  			}
  		}
  	}

  	getNextColor(id, colors = this.data)
  	{
  		const size = colors.length;

  		for (let i in colors ) {

  			if (colors[i].id == id ) {

  				let next = parseInt(i)+1;
				if( next < size ) {
  					return colors[next];
  				} else {
					return colors[0];
  				}
  			}
  		}
	}

  	getPreviousColor(id, colors = this.data)
  	{
  		const size = colors.length;

  		for (let i in colors ) {
  			if (colors[i].id == id ) {
  				let prev = parseInt(i)-1;
  				if( prev == -1 ) {
  					return colors[size-1];
  				} else {
  					return colors[prev];
  				}
  			}
  		}
  	}

}
